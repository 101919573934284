import { useAppStore } from '@/stores/app';
import { Text } from '@/components/Text';
import { ToastType } from '@/types/toast';
import { cn } from '@/lib/utils';

type ToastStyles = {
  [key in ToastType]: string;
};

const toastStyles: ToastStyles = {
  [ToastType.SUCCESS]: 'bg-green text-black',
  [ToastType.ERROR]: 'bg-error text-black',
  [ToastType.INFO]: 'bg-blue text-black',
};

export const Toast = () => {
  const { toast } = useAppStore(s => ({ toast: s.toast, }));

  if (!toast) return null;
  return (
    <div
      className={cn(
        'toast toast-top toast-center top-4 z-50 p-4 rounded-lg shadow-md flex justify-between',
        toastStyles[toast.type],
      )}
    >
      <Text variant="body-1">{toast.message}</Text>
    </div>
  );
};
