import { formatTime } from '@/utils/time';
import { FC, useEffect, useState } from 'react';

export const useCountdownTimer = (initialTime: number, onFinish: () => void) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (!initialTime) return;
    setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (!initialTime) return;
    const startTimer = () => {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            onFinish();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return timer;
    };

    const timerId = startTimer();

    return () => clearInterval(timerId);
  }, [onFinish, initialTime]);

  const resetTimer = (newTime: number) => {
    if (newTime === 0 || !newTime) {
      onFinish();
      return;
    }
    setTimeLeft(newTime);
  };

  return {
    timeLeft: timeLeft,
    timeStr: formatTime(timeLeft),
    resetTimer,
  };
};
