import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { signIn } from "next-auth/react";
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { JWT } from 'next-auth/jwt';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getIdToken } from 'firebase/auth';
import { sendEmailVerification as firebaseSendEmailVerification, sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';
import { UserCredential, signInWithCustomToken, sendEmailVerification } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
let firebaseApp: FirebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0];
}

const auth: Auth = getAuth(firebaseApp);

type MagicLinkData = {
  email: string;
  callbackUrl: string;
};

const getMagicLinkData = (): MagicLinkData | null => {
  const data = localStorage.getItem('magic-link-data');
  return data ? JSON.parse(data) : null;
};

const setMagicLinkData = (data: MagicLinkData) => {
  localStorage.setItem('magic-link-data', JSON.stringify(data));
};

/**
 * The email link flow involves several steps to authenticate a user using their email address.
 * 
 * 1. Send Sign-In Link:
 *    - The `sendSignInLink` function sends a sign-in link to the user's email address.
 *    - It uses the `sendSignInLinkToEmail` function from Firebase Auth.
 *    - The sign-in link contains a URL that the user can click to complete the sign-in process.
 *    - The function also stores the email and callback URL in local storage for later use.
 * 
 * 2. Complete Sign-In:
 *    - The `completeSignIn` function completes the sign-in process using the email link.
 *    - It retrieves the email and callback URL from local storage.
 *    - If the email is not available, it prompts the user to provide their email address.
 * 
 * 3. Authenticate Email Link:
 *    - The `authenticateEmailLink` function authenticates the user using the email link.
 *    - It uses the `signInWithEmailLink` function from Firebase Auth to sign in the user.
 *    - After successful sign-in, it retrieves the user's ID token, access token, and refresh token.
 *    - The function returns the user information, including the tokens.
 *    - This function is used by the `[...nextauth]` API route to authenticate the user.
 * 
 */
const sendSignInLink = async (email: string, callbackUrl: string) => {
  setMagicLinkData({ email, callbackUrl });
  setMagicLinkData({ email: email, callbackUrl: callbackUrl });
  await sendSignInLinkToEmail(auth, email, {
    url: new URL('/completeSignIn', process.env.NEXT_PUBLIC_AUTH_URL).toString(),
    handleCodeInApp: true,
  });
  return null;
};

const getUserFromUserCredential = async (userCredential: UserCredential) => {
  const user = userCredential.user;
  if (user) {
    const idToken = await getIdToken(user);
    const idTokenResult = await user.getIdTokenResult();
    const accessToken = idTokenResult.token;
    const refreshToken = user.refreshToken;              
    return { 
      id: userCredential.user.uid, 
      email: userCredential.user.email,
      idToken: idToken,
      accessToken: accessToken,
      refreshToken: refreshToken,
      accessTokenExpires: Date.parse(idTokenResult.expirationTime),
    };
  }

  return null;
};

const renewUserIdToken = async (token: JWT) => {
  const url = `https://securetoken.googleapis.com/v1/token?key=${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`;
  const options: RequestInit = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: token.refreshToken,
    }),
  };

  const response = await fetch(url, options);
  const refreshedTokens = await response.json();
  if (!response.ok) {
    throw refreshedTokens;
  }

  if (refreshedTokens && refreshedTokens.user_id === token.sub) {
      return {
      ...token,
      idToken: refreshedTokens.id_token,
      accessToken: refreshedTokens.id_token,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken,
      accessTokenExpires: Date.now() + refreshedTokens.expires_in * 1000,
    };
  }
  else if (refreshedTokens && refreshedTokens.user_id != token.sub) {
    console.error(`[DEBUG] refreshedTokens.user_id != token.sub: ${refreshedTokens.user_id} != ${token.sub}`);
  }
  return token;
}

const completeSignIn = async (emailLink: string) : Promise<string | null> => {
  if (! isSignInWithEmailLink(auth, emailLink)) 
    throw new Error('Invalid email link');

  let { email, callbackUrl } = getMagicLinkData() || {};
  if (!email) {
      email = window.prompt('Please provide your email for confirmation') || undefined;
  }

  if (email) {
    const result = await signIn("credentials", {
      email: email,
      emailLink: emailLink,
      signInMethod: 'magic-link',
      redirect: false,
    });

    if (result && result.error) {
      throw new Error(result.error);
    }

  } else {
    throw new Error('No email provided for sign-in');
  } 

  return callbackUrl || null;
};

const authenticateEmailLink = async (email: string, emailLink: string) => {
  const userCredential = await signInWithEmailLink(auth, email, emailLink);
  return await getUserFromUserCredential(userCredential);
};

const createUserWithEmailPassword = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = await getUserFromUserCredential(userCredential);
  if (user && user.email) {
    await firebaseSendEmailVerification(userCredential.user, {
      url: new URL(`/verifyEmail/${encodeURIComponent(user.email)}`, process.env.NEXT_PUBLIC_AUTH_URL).toString(),
      handleCodeInApp: true,
    });
  }
  return user;
}

const authenticateWithEmailPassword = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return await getUserFromUserCredential(userCredential);
}

// Resend email verification link
// There was sendEmailVerification in firebase, 
//      and that function sends out initial verification email, when credential.user is accessible
// This resendEmailVerification is for more generic verification link resend
// And, its caller will be responsible for exception/error handling
const sendVerificationEmail = async (email: string) => {
  try {
    const check_response = await fetch('/api/check_email_for_verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (!check_response.ok) {
      const errorData = await check_response.json();
      throw new Error(errorData.message || 'Not a valid email for verification');
    }

    const response = await fetch('/api/get_firebase_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to resend verification email');
    }

    const { customToken } = await response.json();
    console.log(`[DEBUG] customToken: ${customToken}`);
    const userCredential = await signInWithCustomToken(auth, customToken);
    await firebaseSendEmailVerification(userCredential.user, {
      url: new URL(`/verifyEmail/${encodeURIComponent(email)}`, process.env.NEXT_PUBLIC_AUTH_URL).toString(),
      handleCodeInApp: true,
    });
  } catch (error) {
    showToast(`Error sending verification email: ${error}`, ToastType.ERROR);
    throw error;
  }  
}

const sendPasswordResetEmail = async (email: string): Promise<void> => {
  await firebaseSendPasswordResetEmail(auth, email);
};

const logout = async () => {
  await auth.signOut();
};

export {sendSignInLink, completeSignIn, authenticateEmailLink, authenticateWithEmailPassword, createUserWithEmailPassword, sendVerificationEmail, sendPasswordResetEmail, renewUserIdToken, logout };
