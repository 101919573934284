import { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { useAppStore } from '@/stores/app';

export const useIsAuthenticated = () => {
  const { data: session, status } = useSession();
  const setSession = useAppStore((state) => state.setSession);

  useEffect(() => {
    if (status === 'authenticated') {
      setSession(session);
    }
    else {
      setSession(null);
    }
  }, [status]); 

  return status === 'authenticated';
};