import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { AppLayout } from '@/components/layout/AppLayout';
import '@/styles/globals.css';
import { Toast } from '@/components/Toast';
import ErrorBoundary from '@/components/ErrorBoundary';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { setUtmCookies } from '@/utils/cookie';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    }
  })
}

export default function App({ Component, router, pageProps: { session, ...pageProps } }: AppProps) {
  const pathName = router.pathname;

  useEffect(() => {
    // Google Tag Manager
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID
    }
    TagManager.initialize(tagManagerArgs);

    // PostHog: Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, []);

  useEffect(() => {
    // Set UTM cookies on initial load
    setUtmCookies();

    // Set UTM cookies only when URL contains UTM parameters
    const handleRouteChange = (url: string) => {
      if (url.includes('utm_')) {
        setUtmCookies();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <PostHogProvider client={posthog}>
      <ErrorBoundary>
        <SessionProvider session={session}>
          <AppLayout pathName={pathName}>
            <Component {...pageProps} />
            <Toast />
          </AppLayout>
        </SessionProvider>
      </ErrorBoundary>
    </PostHogProvider>
  );
}