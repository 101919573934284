import userApi from '@/api/user';
import { callWithLoading, stateBase } from '@/helpers/store';
import { UserProfile, UserStats } from '@/models/user';
import { StateBase } from '@/types/store';
import { UpdateUserProfileRequest } from '@/types/user';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { useCharactersStore } from './characters';
import { useProductsStore } from './products';

export interface UserState extends StateBase {
  user: UserProfile | null;
  userStats: UserStats;
  getUserProfile: () => void;
  updateUserProfile: (data: UpdateUserProfileRequest) => void;
  getUserStats: () => Promise<void>;
  setUserStats: (stats: Partial<UserStats>) => void;
  updateEmailVerified: (email: string) => Promise<void>;
  reset: () => void;
}

export const useUserStore = create<UserState>()((set, get) => ({
  ...stateBase,
  user: null,
  userStats: { gem_balance: 0, login_email: null, email_verified: null, is_internal: false },
  initialize: async () => {
    const { initialized, getUserProfile, getUserStats } = get();

    if (!initialized) {
      await Promise.all([getUserProfile(), getUserStats()]);
      useCharactersStore.getState().getUserRecentChats();
      useProductsStore.getState().getProductsAndPromotions();
      set({ initialized: true });
    }
  },
  getUserProfile: async () => {
    const res = await callWithLoading<UserProfile | AxiosError<any, any>>(userApi.getUserProfile(), set);
    if (res instanceof AxiosError) {
      if (res.response?.status !== 401) {
        showToast(`Can\'t get user profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      }
    } else {
      set({ user: res });
    }
  },
  updateUserProfile: async (data: UpdateUserProfileRequest) => {
    const res = await callWithLoading<UserProfile | AxiosError<any, any>>(userApi.updateUserProfile(data), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t update user profile: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      set({ user: res });
      showToast('Profile is successfully updated!', ToastType.SUCCESS);
    }
  },
  setUserStats: stats => set(state => ({ userStats: { ...state.userStats, ...stats } })),
  getUserStats: async () => {
    const res = await userApi.getUserStats();

    if (res instanceof AxiosError) {
      showToast(`Can\'t get user stats: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      set({ userStats: res });
    }
  },
  updateEmailVerified: async (email: string) => {
    const res = await userApi.updateEmailVerified(email);

    if (res instanceof AxiosError) {
      showToast(`Can\'t update email verified: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      showToast('Email verified!', ToastType.SUCCESS);
    }
  },
  reset: async () => set({ ...stateBase, user: null }),
}));

