import Head from 'next/head';
import LocalStorageUtils from '@/utils/storage';
import { PropsWithChildren, useEffect, useState } from 'react';
import { toTitleCase } from '../../utils/string';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'next/router';
import { openModal } from '../BaseModal';
import { Modals } from '@/types/modals';
import { useAppStore } from '@/stores/app';
import { IS_ONBOARDED } from '@/contants/storageKeys';
import SignInModal from '@/components/modals/SignIn';
import { StorageModal } from '@/components/modals/Storage';
import { SuccessPaymentModal } from '@/components/modals/SuccessPayment';
import { WelcomeModal } from '@/components/modals/Welcome';
import posthog from 'posthog-js';

interface Props {
  pathName: string;
}

export function AppLayout({ pathName, children }: PropsWithChildren<Props>) {
  const isSignedIn = useIsAuthenticated();
  const initUser = useUserStore(s => s.initialize);
  const initCharacters = useCharactersStore(s => s.initialize);
  const { banners, isSignInModalVisible, getBanners, setSignInUiType, setIsSignInModalVisible } = useAppStore(s => s);
  const router = useRouter();
  const isOnboarded = LocalStorageUtils.getItem<boolean>(IS_ONBOARDED);

  useEffect(() => {
    if (router.query?.auth_required && !isSignedIn) {
      setIsSignInModalVisible(true);
      setSignInUiType('signinUI');
      openModal(Modals.SignIn);
      window.gtag && window.gtag('event', 'signin_attempt', {'location': 'app_layout_query'});
      posthog.capture('signin_attempt', {'location': 'app_layout_query'});
      }
  }, [router.query, isSignedIn]);

  useEffect(() => {
    if (initCharacters) {
      initCharacters();
    }
  }, [initCharacters]);

  useEffect(() => {
    if (!banners?.length) getBanners();
  }, [getBanners, banners?.length]);

  useEffect(() => {
    if (isSignedIn && initUser) {
      initUser();
      if (!isOnboarded) {
        openModal(Modals.Welcome);
      }
    }
  }, [isSignedIn, isOnboarded, initUser]);

  const closeSignInModal = () => setIsSignInModalVisible(false);

  return (
    <>
      <Head>
        {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{`ThotChat - ${getHeadTitle(pathName)}`}</title>
        <meta name="description" content="ThotChat" />
      </Head>
      <div className="main">{children}</div>
      <SignInModal isVisible={isSignInModalVisible} onClose={closeSignInModal}/>
      <StorageModal />
      <SuccessPaymentModal />
      <WelcomeModal />
    </>
  );
}

function getHeadTitle(pathName: string) {
  const segments = pathName.split('/');
  if (segments.length <= 1 || !segments[1]) return 'Home';
  else return toTitleCase(segments[1]);
}
